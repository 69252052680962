var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "시나리오 목록",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.grid.data,
          merge: _vm.grid.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "riskHazardPicture"
                  ? [
                      _c("q-btn", {
                        attrs: {
                          unelevated: "",
                          round: "",
                          dense: "",
                          size: "7px",
                          color: "primary",
                          icon: "wallpaper",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openHazardPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "picture"
                  ? [
                      _c("q-btn", {
                        attrs: {
                          unelevated: "",
                          round: "",
                          dense: "",
                          size: "7px",
                          color: "primary",
                          icon: "wallpaper",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openImprPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "customCol"
                  ? [
                      props.row["ram4mAssessImprIds"] &&
                      props.row["stepFlagNames"]
                        ? _vm._l(
                            _vm.$comm.fmImprTagItems(props.row),
                            function (item, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: item.color,
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClick(item, props.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.title) + " "),
                                  _c("c-assess-tooltip", {
                                    attrs: {
                                      isVendor: "",
                                      ibmTooltip: item.ibmFmTooltip,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          )
                        : void 0,
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }